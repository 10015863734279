import { Try } from "funfix-core"
import { List } from "immutable"
import _isArray from "lodash/isArray"
import { DomainProduct } from "product/models/DomainProduct"
import { KeyAndLabel } from "../product/VariantProxy"
import { notUndefined } from "./PrismicIdAggregator"
import { PrismicSlice } from "./PrismicModels"

export type CollectionOfLooks = {
  title: string
  looks: {
    title: string | undefined
    products: ProjectionWithPreferredColor[]
  }[]
}

export class RecommendationFilter {
  public products: DomainProduct[]
  public slices: PrismicSlice[]

  constructor(products: DomainProduct[], slices: PrismicSlice[]) {
    this.products = products
    this.slices = slices
  }

  public collectionsForSection(
    sectionId: string | undefined
  ): CollectionOfLooks[] {
    return this.collectionNamesForSection(sectionId).map(collectionName => ({
      title: collectionName,
      looks: this.looksForCollection(sectionId, collectionName).map(
        lookName => ({
          title: lookName,
          products: this.productsForLook(sectionId, collectionName, lookName)
        })
      )
    }))
  }

  public collectionsForCategory(
    category: string,
    categoryModification: string | undefined
  ): CollectionOfLooks[] {
    return []
  }

  public productsForAttribute(
    attributeName: string,
    attributeValue: string
  ): DomainProduct[] {
    return this.products.filter(product =>
      this.productHasAttribute(product, attributeName, attributeValue)
    )
  }

  public productHasAttribute(
    product: DomainProduct,
    attributeName: string,
    attributeValue: string
  ): boolean {
    attributeName.replace(/-/g, "")
    return (
      List([product.masterVariant, ...product.variants])
        .filter(variant =>
          Object.keys(variant)
            .map(key => key.toLowerCase())
            .includes(attributeName.toLowerCase())
        )
        .flatMap(it => {
          const attribute = it[attributeName]

          if (_isArray(attribute)) {
            return attribute as KeyAndLabel[]
          } else {
            return [attribute as KeyAndLabel]
          }
        })
        .filter(value => {
          const valueKeyMatches = Try.of(
            () => value.key.toLowerCase() === attributeValue.toLowerCase()
          ).getOrElse(false)

          const localisedKeyLabelStringMatches = Try.of(
            () =>
              Object.values(value.label).findIndex(
                it => it.toLowerCase() === attributeValue.toLowerCase()
              ) > -1
          ).getOrElse(false)

          const localisedStringMatches = Try.of(
            () =>
              Object.values(value).findIndex(
                it => it.toLowerCase() === attributeValue.toLowerCase()
              ) > -1
          ).getOrElse(false)

          const stringMatches =
            String(value).toLowerCase() === attributeValue.toLowerCase()

          return (
            valueKeyMatches ||
            localisedKeyLabelStringMatches ||
            localisedStringMatches ||
            stringMatches
          )
        }).size > 0
    )
  }

  public collectionNamesForSection(sectionId: string | undefined): string[] {
    return this.slices
      .filter(it => it.primary.product_recommendation_section === sectionId)
      .flatMap(it => it.primary.dropdown_name || [])
      .map(it => it.text)
  }

  public looksForCollection(
    sectionId: string | undefined,
    collectionName: string
  ): (string | undefined)[] {
    return [
      ...new Set(
        this.slices
          .filter(
            it =>
              it.primary.product_recommendation_section === sectionId &&
              (it.primary.dropdown_name || [])
                .map(it => it.text)
                .indexOf(collectionName) > -1
          )
          .flatMap(it => it.items.map(it => it.tab))
      )
    ]
  }

  public productsForLook(
    sectionId: string | undefined,
    collectionName: string,
    lookName: string | undefined | null
  ): ProjectionWithPreferredColor[] {
    return this.slices
      .filter(
        it =>
          it.primary.product_recommendation_section === sectionId &&
          (it.primary.dropdown_name || [])
            .map(it => it.text)
            .indexOf(collectionName) > -1
      )
      .flatMap(it => it.items)
      .filter(it => it.tab === lookName)
      .map(item => {
        const product = this.products.find(
          it => it.key === item.product_id?.productCode
        )

        if (product) {
          return {
            product,
            preferredColor: item.preferred_color_code,
            productBadge: item.product_badge,
            productCategory: item.product_category
          }
        } else {
          return undefined
        }
      })
      .filter(notUndefined)
  }

  public productById(id?: string | null): DomainProduct | undefined {
    return this.products.find(p => p.key === id)
  }

  public productsList(id?: string): ProjectionWithPreferredColor[] {
    return this.products
      .map(item => {
        const product = this.products.find(it => it.key === id)

        if (product) {
          return {
            product
          }
        } else {
          return undefined
        }
      })
      .filter(notUndefined)
  }
}

export interface ProjectionWithPreferredColor {
  preferredColor?: string
  productBadge?: string
  productCategory?: string
  product: DomainProduct
}
