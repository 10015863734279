//jsxhook

import { DomainProduct } from "product/models/DomainProduct"
import React, { useContext } from "react"
import { useStoreContext } from "../i18n/StoreHooks"
import { SlimListingProduct } from "../product/models/SlimListingProduct"
import logger from "../utils/logger"
import { PrismicSlice } from "./PrismicModels"
import {
  RecommendationFilter,
  ProjectionWithPreferredColor
} from "./RecommendationFilter"

export type ProductsByCategory = Record<string, string[]>

export const PrismicProductsContext = React.createContext<{
  products: DomainProduct[]
}>({
  products: []
})

export const PrismicRecommendedSlicesContext = React.createContext<
  PrismicSlice[]
>([])

export const useRecommendations = (): RecommendationFilter => {
  const { products } = useContext(PrismicProductsContext)
  const slices = useContext(PrismicRecommendedSlicesContext)
  return new RecommendationFilter(products, slices)
}

export const useCategorisedProducts = () => {
  const { country } = useStoreContext()

  return {
    fetchProductsForCategory: (
      categoryKey: string,
      filter?: string,
      limit = 10
    ): Promise<ProjectionWithPreferredColor[]> => {
      const uri = `/api/categories/${categoryKey}/${filter}?limit=${limit}&store=${country}`
      return fetch(uri)
        .then(it => it.json() as unknown as SlimListingProduct[])
        .then(it =>
          it.map(
            product =>
              ({
                product
              }) as ProjectionWithPreferredColor
          )
        )
        .catch(err => {
          logger.warn(`Unable to fetch products for category: ${uri}`, err)
          return []
        })
    }
  }
}
