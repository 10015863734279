import { Option } from "funfix-core"
import { useContext } from "react"
import { useStoreContext } from "../i18n/StoreHooks"
import {
  PrismicDataAdapter,
  PrismicItemAdapter
} from "../prismic/PrismicAdapters"
import { linkForDocumentIdBuilder } from "../prismic/PrismicLinkedDocumentContext"
import { PrismicDocument, PrismicDocumentLink } from "../prismic/PrismicModels"
import { useRootSelector } from "../State"
import { NavigationContext } from "./NavigationContext"

interface NavigationItem {
  title: string
  document: PrismicDocument
  main_url?: PrismicDocumentLink
}

interface NavigationApi {
  items: NavigationItem[]
  allowed_product_filters: string[]
  getTrayForItem: () => void
}

export const useNavigationContext = () => useContext(NavigationContext)

export const useNavigationDocument = () => useNavigationContext().navigation

export const useNavigationTrayDocument = (): PrismicDocument | undefined =>
  useRootSelector(it => it.layoutState.tray)

export const useNavigationTrayDocuments = () =>
  useNavigationContext().navigationTrays || []

export const useNavigationLinkedDocuments = () =>
  useNavigationContext().navigationLinkedDocuments || []

export const usePrismicNavigationLink = () => {
  const { country } = useStoreContext()
  const linkedDocuments = useNavigationLinkedDocuments()
  const linkForDocumentId = linkForDocumentIdBuilder(linkedDocuments, country)
  return {
    linkForDocumentId
  }
}

export const useTopLevelNavigation = (): NavigationApi => {
  const navigation = useNavigationDocument()
  const navigationTrays = useNavigationTrayDocuments()

  const items = (navigation?.data?.navigation_items || [])
    .map(it => {
      const adapter = new PrismicItemAdapter(it)
      const title = adapter.label("")
      const document = navigationTrays.find(
        tray => tray.id === adapter.linkId("")
      )
      return { ...it, title, document }
    })
    .filter(it => !!it.document)
    .map(it => ({ ...it, document: it.document! }))

  return {
    getTrayForItem: () => true,
    allowed_product_filters: navigation?.data?.allowed_product_filters || [],
    items
  }
}

export const useTrayColumns = () => {
  const trayDocument = Option.of(
    useRootSelector(it => it.layoutState.tray)
  ).map(it => new PrismicDataAdapter(it?.data || {}))

  const columnOne = trayDocument
    .map(it => ({
      title: it.columnOneTitle(""),
      column: it.columnOne([])
    }))
    .getOrElse(undefined)

  const columnTwo = trayDocument
    .map(it => ({
      title: it.columnTwoTitle(""),
      column: it.columnTwo([])
    }))
    .getOrElse(undefined)

  const columnThree = trayDocument
    .map(it => ({
      title: it.columnThreeTitle(""),
      column: it.columnThree([])
    }))
    .getOrElse(undefined)

  const columns = [columnOne, columnTwo, columnThree]

  return columns
}
